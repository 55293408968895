import { Component } from '@angular/core';
import { FrontContentComponent } from '../../../../components/front-content/front-content.component';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [FrontContentComponent],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
})
export class NotFoundComponent {}
