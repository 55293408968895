import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { PlatformIdService } from '@ckmk/angular';

@Component({
  selector: 'front-content',
  standalone: true,
  imports: [],
  templateUrl: './front-content.component.html',
  styleUrl: './front-content.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FrontContentComponent implements OnInit, OnChanges {
  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
