import { Route } from '@angular/router';
import { HomeComponent } from '../../../modules/front/modules/home/views/home/home.component';

export default <Route>{
  path: '',
  children: [
    {
      path: '',
      component: HomeComponent,
    },
    {
      path: 'home',
      component: HomeComponent,
    },
  ],
};
