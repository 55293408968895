import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontSectionComponent } from '../front-section/front-section.component';
import { NewItemsCard } from '../../../../types/types';
import { getDefaultNews } from '../../../../utils/news.utils';
import { NewItemCardComponent } from '../../modules/news/components/new-item-card/new-item-card.component';
import { HeadlineComponent } from '../../../../components/headline/headline.component';
import { PhoneCarouselComponent } from '../../../../components/phone-carousel/phone-carousel.component';
import { PhoneCarouselItemComponent } from '../../../../components/phone-carousel-item/phone-carousel-item.component';

@Component({
  selector: 'home-news',
  standalone: true,
  imports: [
    FrontSectionComponent,
    NewItemCardComponent,
    HeadlineComponent,
    PhoneCarouselComponent,
    PhoneCarouselItemComponent,
  ],
  templateUrl: './home-news.component.html',
  styleUrl: './home-news.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeNewsComponent implements OnInit, OnChanges {
  protected news!: NewItemsCard;

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {
    this._loadNews();
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  private _loadNews(): void {
    this._getNews().then((events) => (this.news = events));
  }

  private _getNews(): Promise<NewItemsCard> {
    return new Promise(async (resolve) => {
      resolve(getDefaultNews());
    });
  }
}
