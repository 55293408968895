import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FrontSectionComponent } from '../front-section/front-section.component';
import { HeadlineComponent } from '../../../../components/headline/headline.component';

@Component({
  selector: 'home-programs',
  standalone: true,
  imports: [FrontSectionComponent, HeadlineComponent],
  templateUrl: './home-programs.component.html',
  styleUrl: './home-programs.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HomeProgramsComponent implements OnInit, OnChanges {
  public constructor(private _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {}
}
