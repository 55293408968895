import {
  DashboardMenuItems,
  DashboardViewServiceInterface,
  DashboardGroupsMenuItems,
} from '@ckmk/angular';
import { environment } from '../../../../../environments/environment';

export class DashboardViewService implements DashboardViewServiceInterface {
  public getSiteName(): string {
    return environment.SITE_NAME;
  }

  public getLogo(): string {
    return '/img/logo_website.png';
  }

  public generalNavItems(): DashboardMenuItems | Promise<DashboardMenuItems> {
    return [];
  }

  public itemsNavItems(): DashboardMenuItems | Promise<DashboardMenuItems> {
    return [];
  }

  public settingsNavItems(): DashboardMenuItems | Promise<DashboardMenuItems> {
    return [];
  }

  public groupNavItems():
    | DashboardGroupsMenuItems
    | Promise<DashboardGroupsMenuItems> {
    return [];
  }
}
