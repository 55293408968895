import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'front-section',
  standalone: true,
  imports: [],
  templateUrl: './front-section.component.html',
  styleUrl: './front-section.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FrontSectionComponent implements OnInit, OnChanges {
  @Input({ required: true })
  public name!: string;

  @Input({ required: false })
  public enableGoto: boolean = false;

  public constructor(private readonly _self: ElementRef) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
