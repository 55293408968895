import { Component } from '@angular/core';

@Component({
  selector: 'app-event-show',
  standalone: true,
  imports: [],
  templateUrl: './event-show.component.html',
  styleUrl: './event-show.component.scss'
})
export class EventShowComponent {

}
