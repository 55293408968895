<ng-container>
  <front-content>
    <!-- The about section -->
    <home-about />

    <!-- The news section -->
    <home-news />

    <!-- The programs section -->
    <home-programs />
  </front-content>
</ng-container>
