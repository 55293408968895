<ng-container>
  <front-section [name]="'news'" [enableGoto]="true" class="bg-black">
    <headline [label]="'Our news'" [includeSvg]="true" [svgFill]="'white'" class="headline-news"/>

    @defer (when news && news.length) {
      <phone-carousel>
        @for (newItem of news; track $index) {
          <phone-carousel-item>
            <new-item-card [item]="newItem"/>
          </phone-carousel-item>
        }
      </phone-carousel>
    }

    <div class="see-more-container">
      <a href="#" class="see-more">
        More news on social medias
      </a>
    </div>
  </front-section>
</ng-container>
