import { Route } from '@angular/router';
import { FrontComponent } from '../../modules/front/components/front/front.component';
import HomeRoutes from './routes/home.routes';
import AboutRoutes from './routes/about.routes';
import NewsRoutes from './routes/news.routes';
import ProgramsRoutes from './routes/programs.routes';
import GalleryRoutes from './routes/gallery.routes';
import AgendaRoutes from './routes/agenda.routes';
import ContactRoutes from './routes/contact.routes';
import NotFoundRoutes from './routes/not-found.routes';

export default {
  path: '',
  component: FrontComponent,
  children: [
    HomeRoutes,
    AboutRoutes,
    NewsRoutes,
    ProgramsRoutes,
    GalleryRoutes,
    AgendaRoutes,
    ContactRoutes,
    NotFoundRoutes,
  ],
} as Route;
