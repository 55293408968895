import {
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { getMenuNavs } from '../../../../utils/menu.utils';
import { MenuNavItems } from '../../../../types/types';
import { PlatformIdService, TranslationModule } from '@ckmk/angular';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'menu-front',
  standalone: true,
  imports: [FontAwesomeModule, TranslationModule, RouterLink, RouterLinkActive],
  templateUrl: './menu-front.component.html',
  styleUrl: './menu-front.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class MenuFrontComponent implements OnInit, OnChanges {
  protected siteName: string = environment.SITE_NAME;

  protected icons = {
    close: faXmark,
    burger: faBars,
  };

  protected navItems: MenuNavItems = getMenuNavs();

  protected opened: boolean = false;

  public constructor(
    private readonly _self: ElementRef,
    private readonly _platformIdService: PlatformIdService,
  ) {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}

  protected toggleOpen(): void {
    this.opened = !this.opened;

    this._toggleAbleCropBody();
  }

  protected isOpened(): boolean {
    return this.opened || this._isBiggerThenPhoneScreen();
  }

  private _isBiggerThenPhoneScreen(): boolean {
    return (
      this._platformIdService.isBrowser() && document.body.offsetWidth >= 768
    );
  }

  private _toggleAbleCropBody(): void {
    if (this._platformIdService.isBrowser()) {
      if (this.opened) {
        document.body.classList.add('overflow-y-hidden');
      } else {
        document.body.classList.remove('overflow-y-hidden');
      }
    }
  }
}
