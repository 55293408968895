import { MenuNavItems } from '../types/types';

export const getMenuNavs = (): MenuNavItems => [
  {
    name: 'about',
    label: 'About',
    path: 'about',
  },
  {
    name: 'news',
    label: 'News',
    path: 'news',
  },
  {
    name: 'programs',
    label: 'Programs',
    path: 'programs',
  },
  {
    name: 'gallery',
    label: 'Gallery',
    path: 'gallery',
  },
  {
    name: 'agenda',
    label: 'Agenda',
    path: 'agenda',
  },
  {
    name: 'contact',
    label: 'Contact',
    path: 'contact',
  },
];
