<ng-container>
  <front-section [name]="'about'" [enableGoto]="true">
    <headline [label]="'About us'" [includeSvg]="true" />

    <headline [type]="3" [align]="'left'" [label]="'What’s Next Level International'" />

    <p>Next Level International Program is designed to develop high-potential prospects from the age of 14 to 21 yld, in order to help them reach the highest level possible. Our goal is to provide all necessary tools to maximize each player potential and become the best version of themselves. We place particular emphasis on supporting the player in close collaboration with their family.</p>

    <p>The project relies on specific programs:  workouts, performance monitoring,  development plan, advice on healthy living, attitude and behaviour, monitoring of academic results, international camps & tournaments.</p>

    <headline [type]="3" [align]="'left'" [label]="'Behind the scene'" />

    <headline [type]="4" [align]="'left'" [label]="'Jonathan TABU'" />

    <p>He’s the founder of this project and as a former professional basketball player with 20 plus years of experience in high-level sports, his goal to offer the best opportunities to younger athletes. He assembled a team of experts from various fields.</p>

    <headline [type]="4" [align]="'left'" [label]="'Damien TITA'" />

    <p>Project coordinator</p>
    <p>Scouting & recruitment cel</p>
  </front-section>
</ng-container>
