import { Component } from '@angular/core';

@Component({
  selector: 'app-new-show',
  standalone: true,
  imports: [],
  templateUrl: './new-show.component.html',
  styleUrl: './new-show.component.scss'
})
export class NewShowComponent {

}
