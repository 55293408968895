import { NewItemsCard } from '../types/types';
import { faker } from '@faker-js/faker';

export const getDefaultNews = (count: number = 3): NewItemsCard => {
  const news: NewItemsCard = [];

  for (let i = 0; i < count; i++) {
    news.push({
      label: faker.word.words(4),
      date: faker.date.between({
        from: '2024-01-01 10:00',
        to: '2026-01-01 10:00',
      }),
      image: faker.image.urlPicsumPhotos({ width: 1280 }),
      description: faker.lorem.sentences(2),
      link: '',
    });
  }

  return news;
};
