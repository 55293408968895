import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MenuFrontComponent } from '../menu-front/menu-front.component';

@Component({
  selector: 'front-container',
  standalone: true,
  imports: [MenuFrontComponent],
  templateUrl: './front-container.component.html',
  styleUrl: './front-container.component.scss',
})
export class FrontContainerComponent implements OnInit, OnChanges {
  public constructor() {}

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {}
}
